@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;

  margin-top: 0;
  margin-bottom: 0;
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
  }
}

.location {
  margin-bottom: 50px;
  width: 100%;
  border: none;
  text-align: start;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 20px;
    padding: 20px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  /* &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  } */
}

/* An image wrapper that defines the images aspect ratio */
/* .aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); 

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); 
  }
} */

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */

  width: 85%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;
  text-align: center;
}

.locationName {
  color: #3f3b3b;
  font-weight: 600;
  font-size: 18px;
  line-height: 0px;
}

.heading {
  color: black;
  font-weight: 400;
  font-size: 30px;
  line-height: 50px;
  margin-left: 20px;
}

.middlePara {
  color: #383838;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.para {
  line-height: 24px !important;
  margin: 0px !important;
  color: #383838;
  font-weight: 400;
  font-size: 16px;
}
.recentProjectContainer p {
  margin-left: 35px;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
}

.imageContainer {
  position: relative;
}

.slider {
  margin: auto;
  width: 95%;
  display: flex;
  height: 280px;
  max-height: auto;
  overflow-y: hidden;
  overflow-x: scroll !important;
  padding: 16px 16px 16px 0px;
  transform: scroll(calc(var(--i, 0) / var(--n) * -100%));
  scroll-behavior: smooth;
}
.slider::-webkit-scrollbar {
  height: 5px;
  width: 150px;
  display: none;
}
.slider::-webkit-scrollbar-track {
  background: transparent;
}
.slider::-webkit-scrollbar-thumb {
  background: #888;
}
.slider::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.slide {
  position: relative;
}
.slide img {
  height: 100%;
  width: 180px;
  margin: 0 10px;
  object-fit: cover;
  cursor: pointer;
  transition: 0.25s ease-in-out;
}
.controlPrevBtn {
  position: absolute;
  top: 40%;
  left: 0;
  background-color: transparent;
  height: 40px;
  line-height: 100px;
  width: 30px;
  text-align: center;
  user-select: none;
  color: #444;
  cursor: pointer;
}
.controlNextBtn {
  position: absolute;
  top: 40%;
  right: 0;
  background-color: transparent;
  height: 40px;
  line-height: 100px;
  width: 30px;
  text-align: center;
  user-select: none;
  color: #444;
  cursor: pointer;
}

.controlPrevBtn button,
.controlNextBtn button,
.controlNextBtn button:hover,
.controlNextBtn button:active,
.controlNextBtn button:visited,
.controlPrevBtn button:hover,
.controlPrevBtn button:active,
.controlPrevBtn button:visited,
.controlPrevBtn button:focus,
.controlNextBtn button:focus {
  background: transparent;
  color: #222;
  font-size: 36px;
  padding: 0px;
  margin: 0px;
  border: none;
}

.slide img.zoomed {
  width: 500px;
  height: 600px;
  position: fixed;
  left: 25%;
  top: 0%;
  z-index: 1000;
  transform: scale(1) translatey(0) !important;
}
.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.45);
  top: 0;
  display: none;
}
.overlay.active {
  display: block;
}

.recommendByContainer p {
  text-align: left;
  font-weight: 600;
  font-size: 18px;
}

.logoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoContainer img {
  width: 100px;
}

@media only screen and (max-width: 600px) {
  .slider {
    padding: 0;
  }
  .slide {
    padding: 16px 10px;
  }
  .slide img {
    margin: 0;
  }
  .control-prev-btn {
    top: 37%;
  }
  .control-next-btn {
    top: 37%;
  }
  .recentProjectContainer p {
    text-align: center;
    margin-left: 0px;
  }

  .logoContainer img {
    margin: 0px 20px;
  }

  .logoContainer {
    overflow-x: scroll;
  }

  .recommendByContainer p {
    text-align: center;
  }
}
